import PropTypes from "prop-types";
import { useRecaptcha } from './hooks/useRecaptcha'
import { useState } from "react";
import {
  isValidUKPhone,
  formatUKPhoneNumber,
} from './utils/functions'
import { verifyPhoneNumber, enrollUserForMFA } from './api'
import { Text, TextInput, TouchableOpacity, StyleSheet, Platform, View } from "react-native";

const MFARegistration = ({ currentUser, redirectUserToHomePage }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCodeId, setVerificationCodeId] = useState();
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState(false);
  const recaptcha = useRecaptcha("sign-up");

  const submitHandler = (event) => {
    event.preventDefault();
    if (codeSent) {
      enrollUserHandler();
    } else {
      if (isValidUKPhone(phoneNumber)) {
        sendVerificationCode();
      } else {
        alert('Something went wrong');
      }
    }
  };

  async function sendVerificationCode() {
    if (!currentUser || !recaptcha) return;
    const formattedNumber = formatUKPhoneNumber(phoneNumber);
    const verificationId = await verifyPhoneNumber(
      currentUser,
      formattedNumber,
      recaptcha
    );
    if (verificationId) {
      setVerificationCodeId(verificationId);
      setCodeSent(true);
    } else {
      alert('Something went wrong');
    }
  }

  async function enrollUserHandler() {
    const response = await enrollUserForMFA(
      currentUser,
      verificationCodeId,
      code
    );
    if (response) {
      redirectUserToHomePage();
    }
  }

  return (
    <>
      {codeSent ? (
        <TextInput
          style={styles.textInput}
          onChangeText={(text) => setCode(text)}
          value={code}
          autoCapitalize="none"
        />
      ) : (
        <>
          <Text style={styles.inputLabel}>
            Enter your phone number
          </Text>
          <Text style={styles.inputLabel}>
            Must be a UK-based number
          </Text>

          <TextInput
            type="phone"
            value={`${phoneNumber}`}
            onChangeText={(e) => setPhoneNumber(e.target.value)}
            pattern="\+44\s?7\d{3}|\(?07\d{3}\)?\s?\d{3}\s?\d{3}"
            required
          />
          {Platform.OS === 'web' ? <div id="sign-up" /> : <View id="sign-up" />}
        </>
      )}
      <TouchableOpacity style={styles.loginButton} onPress={submitHandler}>
        <Text style={styles.loginText}>Submit</Text>
      </TouchableOpacity>
    </>
  );
};

export default MFARegistration;

const styles = StyleSheet.create({
    inputLabel: {
      color: "#fff",
      fontSize: 10,
      letterSpacing: 2,
      marginBottom: 10,
    },
    textInput: {
      backgroundColor: "#fff",
      height: 50,
      borderRadius: 10,
      marginBottom: 10,
      padding: 10,
    },
    loginButton: {
      backgroundColor: "grey",
      height: 60,
      marginVertical: 20,
      borderRadius: 10,
      flexDirection: "row",
      justifyContent: "space-between",
      padding: 10,
      alignItems: "center",
    },
    loginText: {
      color: "#fff",
    },
  });

MFARegistration.propTypes = {
  currentUser: PropTypes.object,
  redirectUserToHomePage: PropTypes.func,
};
