import React from "react";
import { View, TouchableOpacity, Text } from "react-native";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../utils/helpers/roles";

const CategoryScreen = ({ navigation }) => {
  const selectedBuildingHasDropmarking = useSelector(
    (state) => state.buildingList.selectedBuilding?.hasDropmarking
  );
  const userRole = useSelector((state) => state.auth.role);

  const hasWriteAccessToQualityControl =
    userRole &&
    [
      USER_ROLES.ADMIN,
      USER_ROLES.MANAGER,
      USER_ROLES.CLIENT,
      USER_ROLES.SUPERVISOR,
    ].includes(userRole);

  const hasAccessToDropmarking =
    userRole &&
    selectedBuildingHasDropmarking &&
    [USER_ROLES.ADMIN, USER_ROLES.MANAGER, USER_ROLES.SUPERVISOR, USER_ROLES.WINDOW_CLEANER].includes(
      userRole
    );

  const handleQualityControlPress = () => {
    navigation.navigate("QualityControlScreen");
  };

  const handleDropMarkingPress = () => {
    navigation.navigate("Building");
  };
  return (
    <View>
      {!!hasWriteAccessToQualityControl && (
        <TouchableOpacity
          style={{
            backgroundColor: "grey",
            padding: 10,
            borderRadius: 5,
            margin: 10,
          }}
          onPress={handleQualityControlPress}
        >
          <Text style={{ color: "white" }}>Quality Control</Text>
        </TouchableOpacity>
      )}

      {!!hasAccessToDropmarking && (
        <TouchableOpacity
          style={{
            backgroundColor: "grey",
            padding: 10,
            borderRadius: 5,
            margin: 10,
          }}
          onPress={handleDropMarkingPress}
        >
          <Text style={{ color: "white" }}>Drop Marking</Text>
        </TouchableOpacity>
      )}
    </View>
  );
};

export default CategoryScreen;
