import {
  getFirestore,
  collection,
  query,
  where,
  limit,
  getDocs,
  updateDoc,
  doc,
  deleteField,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { firebaseApp } from "../firebase/config";
import { base64ToBlob } from "../utils/helpers/helpers";
import AsyncStorage from "@react-native-async-storage/async-storage";

const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export const uploadImagesToFirebase = async () => {
  try {
    const localStorageItems = await AsyncStorage.getAllKeys();
    const qualityControlKeys = localStorageItems.filter((key) =>
      key.includes("qc")
    );

    for (const qualityControlKey of qualityControlKeys) {
      const base64Images = JSON.parse(
        await AsyncStorage.getItem(qualityControlKey)
      );

      if (!base64Images || !base64Images.images.length) {
        continue;
      }

      const [siteId, auditId, _, tempIdForLocalStorage] =
        base64Images.firebaseRef.split("-");

      const responsesRef = collection(
        db,
        "BuildingsX",
        siteId,
        "audit",
        auditId,
        "responses"
      );

      const findDocQuery = query(
        responsesRef,
        where("tempIdForLocalStorage", "==", tempIdForLocalStorage),
        limit(1)
      );

      const responseData = await getDocs(findDocQuery);

      if (!responseData.empty) {
        const currentResponseData = responseData.docs[0];

        if (currentResponseData.exists) {
          const itemsArray = currentResponseData.data().items;
          const newItems = [];

          const uploadPromises = await Promise.all(
            base64Images.images.map(async (item) => {
              const itemImages = item.images;
              const itemUploadPromises = itemImages.map(async (itemImg) => {
                const image = base64ToBlob(itemImg);
                const storageRef = ref(
                  storage,
                  `images/${siteId}/audit/${auditId}/responses/${Date.now().toString()}/${
                    item.itemName
                  }_${Date.now().toString()}.jpg`
                );
                const uploadTask = await uploadBytes(storageRef, image);
                const url = await getDownloadURL(uploadTask.ref);
                return { itemName: item.itemName, url };
              });

              return Promise.all(itemUploadPromises);
            })
          ).then((results) => results.flat());

          for (const item of itemsArray) {
            const imageObject = uploadPromises.find(
              (obj) => obj.itemName === item.itemName
            );

            if (imageObject) {
              newItems.push({
                ...item,
                imageUrls: [imageObject.url],
              });
            } else {
              newItems.push(item);
            }
          }

          const responseRef = doc(responsesRef, responseData.docs[0].id);

          await updateDoc(responseRef, {
            items: newItems,
            tempIdForLocalStorage: deleteField(),
          });

          await AsyncStorage.removeItem(qualityControlKey);
          console.log("All images uploaded to Firebase Storage.");
        }
      }
    }
  } catch (error) {
    console.error("Error uploading images to Firebase:", error);
  }
};
