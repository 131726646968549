import React, { useState, useEffect } from "react";
import { View, Text, Button, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import { firebaseApp } from "../../firebase/config";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";
import { SelectList } from "react-native-dropdown-select-list";
import { calculateTitleAndScoreLabel } from "../../utils/helpers/helpers";

const QualityControlScreen = ({ navigation }) => {
  const db = getFirestore(firebaseApp);

  const selectedBuilding = useSelector(
    (state) => state.buildingList.selectedBuilding?.site_id
  );
  const buildingRef = collection(db, "BuildingsX");
  const auditColRef = collection(buildingRef, selectedBuilding, "audit");

  const [auditFormToComplete, setAuditFormToComplete] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [scoringOptions, setScoringOptions] = useState([]);

  useEffect(() => {
    if (selectedBuilding) {
      getAuditFormToComplete();
      setSelectedArea(null);
      setSelectedFloor(null);
      setSelectedArea(null);
      setScoringOptions([]);
    }
  }, [selectedBuilding]);

  const getAuditFormToComplete = async () => {
    try {
      const q = query(auditColRef, orderBy("createdAt", "desc"), limit(1));
      const allAuditFormDocs = await getDocs(q);

      let mostRecentAuditForm;

      allAuditFormDocs.forEach((auditForm) => {
        mostRecentAuditForm = auditForm.data();
      });

      if (mostRecentAuditForm) {
        setAuditFormToComplete(mostRecentAuditForm);

        if (mostRecentAuditForm.scoreCriteria) {
          getCustomScoringOptions(mostRecentAuditForm.scoreCriteria);
        } else {
          getDefaultScoringOptions(mostRecentAuditForm.itemsScoredOutOf);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAreaOptions = () =>
    auditFormToComplete.areas
      .filter(({ floors }) => floors.includes(selectedFloor))
      .map(({ areaName }) => ({ label: areaName, value: areaName }));

  const getDefaultScoringOptions = (itemsScoredOutOf) => {
    const arrayRange = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, index) => start + index * step
      );

    const scoringOptions = arrayRange(1, itemsScoredOutOf, 1).map((score) => ({
      label: score,
      value: score,
    }));

    setScoringOptions(scoringOptions);
  };

  const getCustomScoringOptions = (scoringCriteria) => {
    const scoringOptions = calculateTitleAndScoreLabel(scoringCriteria).map(
      (score) => ({
        label: score.scoreLabel,
        value: +score.scoreLabel,
      })
    );

    setScoringOptions(scoringOptions);
  };

  const handleAuditNextPage = () => {
    if (selectedArea) {
      navigation.navigate("QualityControlWizardForm", {
        selectedArea: selectedArea,
        selectedFloor: selectedFloor,
        auditFormToComplete: auditFormToComplete,
        selectedBuilding: selectedBuilding,
        scoringOptions: scoringOptions,
      });
    } else {
      alert("Please select an area or floor before proceeding.");
    }
  };

  return (
    <ScrollView>
      {auditFormToComplete ? (
        <View style={{ padding: 16 }}>
          <Text style={{ fontSize: 24, fontWeight: "bold", marginBottom: 16 }}>
            Complete Audit
          </Text>

          <Text style={{ marginTop: 16, marginBottom: 8 }}>Floor</Text>

          <SelectList
            setSelected={(val) => setSelectedFloor(val)}
            data={auditFormToComplete.floors}
            save="value"
          />

          <Text style={{ marginTop: 16, marginBottom: 8 }}>Areas</Text>
          {selectedFloor && (
            <SelectList
              setSelected={(val) => setSelectedArea(val)}
              data={getAreaOptions()}
              save="value"
            />
          )}

          <View style={{ marginTop: 30 }}>
            <Button
              title="Next"
              disabled={!selectedArea}
              onPress={handleAuditNextPage}
            />
          </View>
        </View>
      ) : (
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            padding: 16,
          }}
        >
          <Text style={{ fontSize: 20 }}>No audit form available.</Text>
        </View>
      )}
    </ScrollView>
  );
};

export default QualityControlScreen;
