import React, { useState } from "react";
import {
  Image,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import {firebaseApp} from "../../firebase/config";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useDispatch } from "react-redux";
import { AntDesign } from "@expo/vector-icons";
import logo from "../../assets/images/wc-logo.png";
import ScreenImageBackground from "../../components/ScreenImageBackground";
import { authActions } from "../../store/auth-slice";
import styles from "./styles";
import { useRecaptcha } from "../../components/MFA/hooks/useRecaptcha";
import MFAPage from "../../components/MFA/MFAPage";
import { verifyUserMFA } from "../../components/MFA/api";

export default function LoginScreen({ navigation }) {
  const auth = getAuth(firebaseApp);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [displayMFAPage, setDisplayMFAPage] = useState(null);
  const [verificationCodeId, setVerificationCodeId] = useState();
  const [resolver, setResolver] = useState();
  const recaptcha = useRecaptcha('sign-in')
  const dispatch = useDispatch();

  const onLoginPress = async () => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error.code === "auth/user-not-found") {
        alert("User not found! Please check your email and try again.");
      } else if (error.code === "auth/wrong-password") {
        alert("Invalid Password! Please try again.");
      } else if (error.code === "auth/too-many-requests") {
        alert(
          "Your account has been temporarily suspended. Please try again later or reset your password"
        );
      } else if (error.code == "auth/multi-factor-auth-required") {
        handleMFA(error);
      } else {
        alert("An unknown error occurred. Please try again later.");
      }
    }
  };
  const redirectUserToHomePage = () => {
    dispatch(authActions.authenticate({ isAuth: true }));
  };
  const handleMFA = async (error) => {
    if (recaptcha) {
      const data = await verifyUserMFA(error, recaptcha, 0);

      if (!data) {
        alert(
          "Something went wrong. Please try again. If the issue persists, please contact us for help."
        );
      } else {
        const { verificationId, resolver } = data;
        setVerificationCodeId(verificationId);
        setResolver(resolver);
      }
      setDisplayMFAPage("login");
    }
  };

  

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flex: 1 }}
      enableOnAndroid={true}
      automaticallyAdjustContentInsets={true}
      enableAutomaticScroll={Platform.OS === "ios"}
    > 
      <View style={styles.container}>
        <ScreenImageBackground>
          <View style={styles.logoContainer}>
            <Image source={logo} style={styles.logo} />
          </View>
          {!!displayMFAPage ? (
            <MFAPage
              type={displayMFAPage}
              redirectUserToHomePage={redirectUserToHomePage}
              resolver={resolver}
              verificationCodeId={verificationCodeId}
            />
          ) : (
            <View style={styles.loginContainer}>
              <View style={styles.loginForm}>
                <Text style={styles.loginTitle}>LOGIN</Text>
                <Text style={styles.loginSubTitle}>Sign in to continue</Text>

                <Text style={styles.inputLabel}>PLEASE ENTER YOUR EMAIL</Text>
                <TextInput
                  style={styles.textInput}
                  onChangeText={(text) => setEmail(text)}
                  value={email}
                  autoCapitalize="none"
                />

                <Text style={styles.inputLabel}>
                  PLEASE ENTER YOUR PASSWORD
                </Text>
                <TextInput
                  style={styles.textInput}
                  secureTextEntry={true}
                  onChangeText={(text) => setPassword(text)}
                  value={password}
                  autoCapitalize="none"
                />

                <TouchableOpacity
                  style={styles.loginButton}
                  onPress={() => onLoginPress()}
                >
                  <Text style={styles.loginText}>Login</Text>
                  <AntDesign name="arrowright" size={24} color="white" />
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => navigation.navigate("ResetPassword")}
                >
                  <Text style={styles.inputLabel}>FORGOT PASSWORD?</Text>
                </TouchableOpacity>
                {Platform.OS === 'web' ? <div id="sign-in" /> : <View id='sign-in' />}
              </View>
            </View>
          )}
        </ScreenImageBackground>
      </View>
    </KeyboardAwareScrollView>
  );
}
