import {
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { firebaseApp } from "../../firebase/config";

const auth = getAuth(firebaseApp);
export function verifyIfUserIsEnrolledForMFA(user) {
  const enrolledFactors = multiFactor(user).enrolledFactors;
  return enrolledFactors.length > 0;
}

export async function verifyPhoneNumber(user, phoneNumber, recaptchaVerifier) {
  const session = await multiFactor(user).getSession();
  const phoneInfoOptions = {
    phoneNumber,
    session,
  };
  const phoneAuthProvider = new PhoneAuthProvider(auth);
  try {
    return await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
  } catch (error) {
    console.log(error);
    alert("Something went wrong.");
    return false;
  }
}

export async function enrollUserForMFA(
  user,
  verificationCodeId,
  verificationCode
) {
  const phoneAuthCredential = PhoneAuthProvider.credential(
    verificationCodeId,
    verificationCode
  );
  const multifactorAssertion =
    PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
  try {
    await multiFactor(user).enroll(
      multifactorAssertion,
      "Personal phone number"
    );
    return true;
  } catch (error) {
    console.log(error);
    alert("Something went wrong.");
    return false;
  }
}

export async function verifyUserMFA(error, recaptchaVerifier, selectedIndex) {
  const resolver = getMultiFactorResolver(auth, error);
  if (
    resolver.hints[selectedIndex].factorId ===
    PhoneMultiFactorGenerator.FACTOR_ID
  ) {
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[selectedIndex],
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier
      );
      return { verificationId, resolver };
    } catch (error) {
      console.log(error);
      alert("Something went wrong.");
    }
  }
}

export async function verifyMFAEnrolledUser(verificationMFA, verificationCode) {
  try {
    const { verificationCodeId, resolver } = verificationMFA;
    const credentials = PhoneAuthProvider.credential(
      verificationCodeId,
      verificationCode
    );
    const multifactorAssertion =
      PhoneMultiFactorGenerator.assertion(credentials);
    return await resolver.resolveSignIn(multifactorAssertion);
  } catch (error) {
    if (error.code === "auth/invalid-verification-code") {
      alert(
        "The code you have entered seems to be invalid. Please check the code and try again."
      );
    } else {
      alert("Something went wrong.");
    }
  }
}
