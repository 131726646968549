import { useGLTF } from "@react-three/drei";
import { MODELS_PATHS } from "pcs-shared-code/dropmarking/utils/constants";
import { useEffect, useState } from "react";

export default function Model({ siteId }) {
  const [modelPath, setModelPath] = useState("");
  useEffect(() => {
    if(siteId) {
        (async () => {
          const path = await getImportedModel(siteId);
          setModelPath(path);
        })();
    }
  }, [siteId]);

  if (!modelPath) return;

  return <ModelContent path={modelPath} />;
}

const ModelContent = ({ path }) => {
  const model = useGLTF(path);
  return <primitive object={model.scene} />;
};

const getImportedModel = async (siteId) => {
  const module = await importedModels[siteId];
  return module.default;
};

const importedModels = {
  "1 Appold Street": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["1 Appold Street"]}`
  ),
  "Fish Coal": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Fish Coal"]}`
  ),
  K3: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.K3}`
  ),
  R7: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.R7}`
  ),
  "Q1 Handyside": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Q1 Handyside"]}`
  ),
  "Western Transit": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Western Transit"]}`
  ),
  "Two Pancras Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Two Pancras Square"]}`
  ),
  "Three Pancras Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Three Pancras Square"]}`
  ),
  "Four Pancras Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Four Pancras Square"]}`
  ),
  "One Pancras Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["One Pancras Square"]}`
  ),
  "11 - 21 Canal Reach": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["11 - 21 Canal Reach"]}`
  ),
  "Coal Drops Yard": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Coal Drops Yard"]}`
  ),
  "10 Cubitt Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["10 Cubitt Square"]}`
  ),
  "S1-Handyside": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["S1-Handyside"]}`
  ),
  "S2 Handyside": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["S2 Handyside"]}`
  ),
  "Television Centre": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Television Centre"]}`
  ),
  "Walt Disney": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Walt Disney"]}`
  ),
  "Bloom Clenkerwell": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Bloom Clenkerwell"]}`
  ),
  "Kings Place": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Kings Place"]}`
  ),
  "Lloyds Of London": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Lloyds Of London"]}`
  ),
  "10 Portman Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["10 Portman Square"]}`
  ),
  "Marble Arch House": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Marble Arch House"]}`
  ),
  "York House": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["York House"]}`
  ),
  "201 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["201 Bishopsgate"]}`
  ),
  "2 London Wall": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["2 London Wall"]}`
  ),
  "One Station Hill": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["One Station Hill"]}`
  ),
  "99 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["99 Bishopsgate"]}`
  ),
  Dashwood: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.Dashwood}`
  ),
  "22 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["22 Bishopsgate"]}`
  ),
  "30 St Mary's Axe": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["30 St Mary's Axe"]}`
  ),
  "3 Sheldon Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["3 Sheldon Square"]}`
  ),
  "4 Kingdom Street": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["4 Kingdom Street"]}`
  ),
  Ropemaker: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.Ropemaker}`
  ),
  "2 Kingdom Street": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["2 Kingdom Street"]}`
  ),
  "6 More London": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["6 More London"]}`
  ),
  "The Crick": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["The Crick"]}`
  ),
  "199 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["199 Bishopsgate"]}`
  ),
  "110 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["110 Bishopsgate"]}`
  ),
  "Exchange House": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Exchange House"]}`
  ),
  Broadwalk: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.Broadwalk}`
  ),
  "Exchange Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Exchange Square"]}`
  ),
  "Broadgate Tower": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Broadgate Tower"]}`
  ),
  "1 Finsbury Avenue": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["1 Finsbury Avenue"]}`
  ),
  "The Shard": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["The Shard"]}`
  ),
  "135 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["135 Bishopsgate"]}`
  ),
  "155 Bishopsgate": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["155 Bishopsgate"]}`
  ),
  "100 Liverpool Street": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["100 Liverpool Street"]}`
  ),
  "One New Change": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["One New Change"]}`
  ),
  "30 North Colonnade": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["30 North Colonnade"]}`
  ),
  "1 London Bridge": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["1 London Bridge"]}`
  ),
  "Cottons Centre": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Cottons Centre"]}`
  ),
  "2 More London": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["2 More London"]}`
  ),
  "Hay's Galleria": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Hay's Galleria"]}`
  ),
  Bluefin: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.Bluefin}`
  ),
  "Wellcome Trust": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Wellcome Trust"]}`
  ),
  "Aldgate Tower": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Aldgate Tower"]}`
  ),
  "Cannon Place": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Cannon Place"]}`
  ),
  "1 Sheldon Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["1 Sheldon Square"]}`
  ),
  CityPoint: import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS.CityPoint}`
  ),
  "Islington Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Islington Square"]}`
  ),
  "20 Fenchurch Street": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["20 Fenchurch Street"]}`
  ),
  "Lloyds Of London Metals": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Lloyds Of London Metals"]}`
  ),
  "183 Euston Road": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["183 Euston Road"]}`
  ),
  "Paddington Square": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Paddington Square"]}`
  ),
  "One Wood Crescent": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["One Wood Crescent"]}`
  ),
  "40 Leadenhall Demo": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["40 Leadenhall Demo"]}`
  ),
  "61 Southwark Street": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["61 Southwark Street"]}`
  ),
  "2 Redman Place": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["2 Redman Place"]}`
  ),
  "Park House": import(
    `pcs-shared-code/dropmarking/DropmarkingBuildings/${MODELS_PATHS["Park House"]}`
  ),
};
