import React, { Suspense } from "react";

import { Canvas } from "@react-three/fiber";
import {
  OrbitControls,
  useProgress,
  Html,
  Stage,
} from "@react-three/drei";
import Model from "./Model";

const Building3D = (props) => {
  const buildingName = props.name;

  return (
    <Canvas>
      <Suspense fallback={<Loader />}>
        <Stage>
          <Model siteId={buildingName} />
        </Stage>
      </Suspense>

      <OrbitControls />
    </Canvas>
  );
};

function Loader() {
  const { active, progress } = useProgress();

  return (
    <Html center>
      {progress} % loaded {active}
    </Html>
  );
}

export default Building3D;
