import PropTypes from "prop-types";
import padlock from "../../assets/images/padlock-closed-gold.png"
import { getAuth } from "firebase/auth";
import { firebaseApp } from "../../firebase/config";
import MFARegistration from "./MFARegistration";
import MFALogin from "./MFALogin";
import { View, Image, StyleSheet } from "react-native";

const MFAPage = ({ type, redirectUserToHomePage, ...rest }) => {
  const auth = getAuth(firebaseApp);
  const currentUser = auth.currentUser;

  return (
    <View style={styles.loginContainer}>
      <View style={styles.logoContainer}>
        <Image source={padlock} style={styles.logo} />
      </View>

      {type === "login" ? (
        <MFALogin {...rest} redirectUserToHomePage={redirectUserToHomePage} />
      ) : (
        <MFARegistration
          currentUser={currentUser}
          redirectUserToHomePage={redirectUserToHomePage}
        />
      )}
    </View>
  );
};

export default MFAPage;

const styles = StyleSheet.create({
  logoContainer: {
    height: 200,
    justifyContent: "flex-end",
    marginHorizontal: 40,
  },
  loginContainer: {
    flex: 1,
    justifyContent: "flex-end",
    marginHorizontal: 40,
  },
  logo: {
    width: 90,
    height: 90,
    resizeMode: "contain",
    margin: 'auto'
  },
});

MFAPage.propTypes = {
  type: PropTypes.string.isRequired,
};
