import { useState } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import PropTypes from "prop-types";
import { verifyMFAEnrolledUser } from "./api";

const MFALogin = ({ resolver, verificationCodeId, redirectUserToHomePage }) => {
  const [code, setCode] = useState();

  const phoneNumber = resolver.hints[0].phoneNumber;

  const submitHandler = (event) => {
    event.preventDefault();
    signVerifiedUserIn();
  };

  const signVerifiedUserIn = async () => {
    const verified = await verifyMFAEnrolledUser(
      { verificationCodeId, resolver },
      code
    );
    if (verified) {
      redirectUserToHomePage();
    }
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.loginForm}>
          <Text style={styles.title}>2-Step Auth</Text>
          <Text style={styles.subtitle}>Enter the code sent to:</Text>
          <Text style={styles.subtitle}>{`${phoneNumber}`}</Text>

          <TextInput
            style={styles.textInput}
            onChangeText={(text) => setCode(text)}
            value={code}
            autoCapitalize="none"
          />

          <TouchableOpacity
            style={styles.loginButton}
            onPress={submitHandler}
          >
            <Text style={styles.loginText}>Submit</Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export default MFALogin;

const styles = StyleSheet.create({
  logoContainer: {
    height: 200,
    justifyContent: "flex-end",
    marginHorizontal: 40,
  },
  container: {
    flex: 1,
    justifyContent: "flex-end",
    marginHorizontal: 40,
  },
  loginForm: {
    height: 500,
    justifyContent: "flex-end",
    marginBottom: 50,
  },
  title: {
    color: "#fff",
    fontSize: 30,
    fontWeight: "700",
    marginBottom: 1,
  },
  subtitle: {
    color: "#fff",
    fontSize: 25,
    marginBottom: 25,
  },
  inputLabel: {
    color: "#fff",
    fontSize: 10,
    letterSpacing: 2,
    marginBottom: 10,
  },
  textInput: {
    backgroundColor: "#fff",
    height: 50,
    borderRadius: 10,
    marginBottom: 10,
    padding: 10,
  },
  loginButton: {
    backgroundColor: "grey",
    height: 60,
    marginVertical: 20,
    borderRadius: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    alignItems: "center",
  },
  loginText: {
    color: "#fff",
  },
});

MFALogin.propTypes = {
  resolver: PropTypes.any,
  verificationCodeId: PropTypes.any,
  redirectUserToHomePage: PropTypes.func,
};
