import { useEffect, useState } from "react";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import {firebaseApp} from '../../../firebase/config'

const auth = getAuth(firebaseApp);
export function useRecaptcha(componentId) {
  const [recaptcha, setRecaptcha] = useState(null);

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      componentId,
      {
        size: "invisible",
        callback: () => {},
      },
      auth
      );

    setRecaptcha(recaptchaVerifier);

    return () => {
      recaptchaVerifier.clear();
    };
  }, [componentId]);
  return recaptcha;
}
