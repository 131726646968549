import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const storage = getStorage();

export const uploadImage = async (image, equipmentId = '') => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();

      const imageRef = ref(storage, `window-cleaning/defects${new Date().getTime()}_` + equipmentId);

      const snapshot = await uploadBytes(imageRef, blob);
      const imageUrl = await getDownloadURL(snapshot.ref);
      return imageUrl;
    } catch (error) {
      console.log(error);
    }
  };